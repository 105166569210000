import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';

const NotFound = () => {

    return (
        <Container maxWidth="lg">
            <Grid style={{padding : "20px"}} container spacing={2}>
            
                <Grid item xs={12} sm={12} lg={12}>
                    
                    <Card>
                        
                        <CardActionArea>
                            <CardMedia
                                style={{height : "300px"}}
                                image= "https://thumbs.gfycat.com/ImperfectPracticalGoldfish-size_restricted.gif"
                                title="This page does not exist anymore or it has been moved, by who else but us!"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                This page does not exist anymore or it has been moved, by who else but us!
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                Error ! :( 404
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    
                    </Card>
        
                </Grid>
            
            </Grid>
        </Container>
    )

}
 
export default NotFound;