import React, {useContext, useState} from 'react'
import {MyContext} from '../contexts/MyContext'
import * as Mui from '@material-ui/core';
import Alert  from '@material-ui/lab/Alert';

import { 
    Link,
    useHistory
} from "react-router-dom";

function Login(){

    const {loginUser,isLoggedIn} = useContext(MyContext);

    const history = useHistory();

    const initialState = {
        userInfo:{
            email:'',
            password:'',
        },
        errorMsg:'',
        successMsg:'',
    }

    const [state,setState] = useState(initialState);

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if(data.success && data.token){

            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn(); 
            history.push('/profile')
        }
        else{

            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });

        }
    }

    // Show Message on Error or Success
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <Alert variant="outlined" severity="error" >{state.errorMsg}</Alert> ;
    }
    if(state.successMsg){
        errorMsg = <Alert variant="outlined" severity="success" >{state.successMsg}</Alert> ;
    }

    return(
       
       <Mui.Container xs={6}  justifycontent="center" >

                <Mui.Grid container 
                              item 
                              xs={12} 
                              sm={12}
                              lg={6} 
                              spacing={3}  
                              justifycontent="center"
                              alignItems="center"
                              style={{"margin":"auto"}}
                              > 
                
                            <Mui.Box p={2} borderColor={"blue"} style={{"width":"100%"}}>
                                <Mui.Card  variant="outlined">
                                    <Mui.CardContent>

                                        <h1 style={{"textAlign":"center", "fontFamily":"arial"}}>Login</h1>

                                        <form onSubmit={submitForm} noValidate autoComplete="off">
                                        
                                        <Mui.FormControl margin="dense" fullwidth="true" style={{width: "100%"}} >
                                            <Mui.TextField 
                                            
                                                autoComplete="username" 
                                                name="email" 
                                                type="email" 
                                                required 
                                            
                                                value={state.userInfo.email} 
                                                onChange={onChangeValue} 
                                               
                                                label="Email Adress"
                                                placeholder="Email Adress"
                                                variant="outlined"
                                            />
                                        </Mui.FormControl>

                                        <Mui.FormControl margin="dense" fullwidth="true" style={{width: "100%"}} >
                                            <Mui.TextField 
                                            
                                                autoComplete="current-password" 
                                                name="password" 
                                                type="password" 
                                             
                                                value={state.userInfo.password} 
                                                onChange={onChangeValue} 

                                                label="Password"
                                                placeholder="Password"
                                                variant="outlined"
                                            />
                                        </Mui.FormControl>
                                        
                                        {errorMsg}
                                        {successMsg}
                                        
                                        <Mui.FormControl margin="dense" fullwidth="true" style={{width: "100%"}} >
                                            <Mui.Button  variant="contained" color="primary"  type="submit">Login</Mui.Button>
                                        </Mui.FormControl>
                                    
                                        </form>
                                    
                                        <Mui.FormControl margin="dense" fullwidth="true" style={{width: "100%"}} >
                                            <Link to="/register" >
                                                <Mui.Button  variant="contained" color="default" >I wish to register</Mui.Button>    
                                            </Link>
                                        </Mui.FormControl>

                                    </Mui.CardContent>    
                                </Mui.Card>    

                            </Mui.Box>


                </Mui.Grid>

        </Mui.Container> 

        
    );
}

export default Login;