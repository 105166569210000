import React, { createContext,Component } from "react";
import axios from 'axios'

export const MyContext = createContext();

// Define the base URL
const Axios = axios.create();

/*
Axios.interceptors.request.use((config) => {
    console.log(config)
});
*/

class MyContextProvider extends Component{
    
    constructor(){
        super();
        this.isLoggedIn();
    }

    // settings 
    settings = {
        baseURL : "https://react-boilerplate.bluelogic.ro/php-backend/", 
        //baseURL : "http://localhost/php-login-registration-api/", 
    }

    // Root State
    state = {
        showLogin:true,
        isAuth:false,
        hasAdminRole:false,
        theUser:null,
    }

    // On Click the Log out button
    logoutUser = () => {
        localStorage.removeItem('loginToken');
        this.setState({
            ...this.state,
            isAuth:false,
            hasAdminRole:false,
        });
    }

    registerUser = async (user) => {

        // Sending the user registration request
        const register = await Axios.post(this.settings.baseURL+'controllers/register.php',{
            name:user.name,
            email:user.email,
            password:user.password 
        })
        /*.then((response) => {
            console.log(response);
          }, (error) => {
            console.log(error);
          });;
        */
        return register.data;
    }


    loginUser = async (user) => {

        // Sending the user Login request
        const login = await Axios.post(this.settings.baseURL+'controllers/login.php',{
            email:user.email,
            password:user.password
        });
        console.log(login)
        return login.data;
    }

    // Checking user logged in or not
    isLoggedIn = async () => {
        const loginToken = localStorage.getItem('loginToken');

        // If inside the local-storage has the JWT token
        if(loginToken){

            // apache does not work with header authorization
            // pass the data and add it later in allheaders variable //// 'bearer '+loginToken;
            //Adding JWT token to axios default header
            //Axios.defaults.headers.common['Authorization'] = 'bearer '+loginToken;
            // Fetching the user information
            //const {data} = await Axios.get(this.settings.baseURL+'controllers/user-info.php');
            
            const {data} = 
            await axios({
                method: "post",
                url: this.settings.baseURL+"controllers/user-info.php",
                data: {
                    token: loginToken
                },
                //headers: { "Content-Type": "multipart/form-data" },
                headers: { "Content-Type": "application/json" },
            })
            
            this.setState({
                ...this.state,
                showLoadingSpinner:false
            });
            
            console.log(data)
            // If user information is successfully received
            if(data.success && data.user){
                
                if(data.user.permission === '1'){
                
                    this.setState({
                        ...this.state,
                        isAuth:true,
                        hasAdminRole:true,
                        theUser:data.user
                    });

                }else if(data.user.permission === '2'){

                    this.setState({
                        ...this.state,
                        isAuth:true,
                        hasAdminRole:false,
                        theUser:data.user
                    });

                }
            }else{
                //alert("Token invalid");
                //location.assign('/login');
                
            }

            
        }else{
            //alert("No Token");
            //location.assign('/login');
            // this.setState({
            //     ...this.state,
            //     showLoadingSpinner:false
            // });
           
        }

        
    }

    // Updating user data
    updateUserDate = async (parameter, action, config=null) => {

        // use this endpoint to post data and route the login by sending the action param.
        // parameter is the main object that is sent
        // Sending the user registration request
        // config is default null, if set it will have diffrent config values for the AXIOS Object
       
        const updData = await axios({
            method: "post",
            url: this.settings.baseURL+"controllers/userInfoController.php",
            data: {
                parameter,
                action
            },
            //headers: { "Content-Type": "multipart/form-data" },
            headers: { "Content-Type": "application/json" },
        })

        if(updData.data)
        {
            // refetch user data
            this.isLoggedIn();

            console.log(updData)
            //return to desired page
            return updData.data
        }    
    }

    // activate user 
    activateUser = async (parameter, action, config=null) => {

        // use this endpoint to post data and route the login by sending the action param.
        // parameter is the main object that is sent
        // Sending the user registration request
        // config is default null, if set it will have diffrent config values for the AXIOS Object
       
        const updData = await axios({
            method: "post",
            url: this.settings.baseURL+"controllers/userInfoController.php",
            data: {
                parameter,
                action
            },
            //headers: { "Content-Type": "multipart/form-data" },
            headers: { "Content-Type": "application/json" },
        })

        if(updData.data)
        {
            console.log(updData)
            //return to desired page
            return updData.data
        }    
    }

    // Updating user pic profile
    updateUserProfilePicture = async (parameter, action, oldImage, config=null) => {

        // use this endpoint to post data and route the login by sending the action param.
        // parameter is the main object that is sent
        // Sending the user registration request
        // config is default null, if set it will have diffrent config values for the AXIOS Object
        //const headers = { 'Accept': 'application/json', "Content-type" : "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2) + ""  }
        const updData = await axios({
            method: "post",
            url: this.settings.baseURL+"controllers/userInfoController.php",
            data: {
                parameter,
                action,
                oldImage
            },
           // headers: headers
        })
        
        if(updData.data)
        {
            
            // refetch user data
            this.isLoggedIn();

            console.log(updData)
            //return to desired page
            return updData.data
        }    
    }

    updateUserProfileCoverPicture = async (parameter, action, oldImage, config=null) => {

        // use this endpoint to post data and route the login by sending the action param.
        // parameter is the main object that is sent
        // Sending the user registration request
        // config is default null, if set it will have diffrent config values for the AXIOS Object
        //const headers = { 'Accept': 'application/json', "Content-type" : "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2) + ""  }
        const updData = await axios({
            method: "post",
            url: this.settings.baseURL+"controllers/userInfoController.php",
            data: {
                parameter,
                action,
                oldImage
            },
           // headers: headers
        })
        
        if(updData.data)
        {
            
            // refetch user data
            this.isLoggedIn();

            console.log(updData)
            //return to desired page
            return updData.data
        }    
    
    }

    render(){
        const contextValue = {
            rootState:this.state,
            rootSettings:this.settings,
            toggleNav:this.toggleNav,
            isLoggedIn:this.isLoggedIn,
            registerUser:this.registerUser,
            loginUser:this.loginUser,
            logoutUser:this.logoutUser,
            updateUserDate: this.updateUserDate,
            updateUserProfilePicture: this.updateUserProfilePicture,
            updateUserProfileCoverPicture: this.updateUserProfileCoverPicture,
            activateUser: this.activateUser
        }

        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;