import React, {useContext, useState} from 'react'
import {MyContext} from '../contexts/MyContext'

import './Profile.css';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Grid, Container} from '@material-ui/core';
import {
    Link
} from "react-router-dom";
import CustoLoader from '../components/CustomLoader';

function Profile(){

    console.log(1)

    const {rootState,rootSettings,updateUserProfileCoverPicture} = useContext(MyContext);
    const {theUser,} = rootState;

    const initialState = {
        isActiveBigAvatar: false,
        avatarClassName: 'Avatar__big_image',
        avatarBgImage: 'url('+rootSettings.baseURL+theUser.img+')',
        isLoading: false
    }
    const [state,setState] = useState(initialState);

    // On file select (from the pop up) 
    const  uploadProfileCoverImg =  async (e) => { 
        // Update the state 
        setState({
            ...state,
            isLoading : true 
        });

        const formAction = 'upd_profile_cover_img';
        const reader = new FileReader();
        
        if(e.target.files.length > 0){
          
            if(window.FileReader) {

                reader.readAsDataURL(e.target.files[0]);
                
                reader.onload = async function(){
                    
                    var dataURL = reader.result;

                    var data = await updateUserProfileCoverPicture( [theUser.email, dataURL, theUser.cover_img] , formAction);

                    console.log([data, "data"])

                    setState({
                        ...state,
                        isLoading : false 
                    });
                
                   
                };

            }
        
        }
        
    }

  
    const  modalOpenImageWithLargeSize = async (e) => { 
      
        if ( state.isActiveBigAvatar === false ) {  

           setState({
                ...state,
                isActiveBigAvatar : true ,
                avatarClassName : 'Avatar__big_image--active',
                avatarBgImage: 'url('+rootSettings.baseURL+theUser.img+')'
            });

            console.log(false)
            console.log(state)
        //   initialState.isActiveBigAvatar  = true 
           
        } else {  
            
           setState({
                ...state,
                isActiveBigAvatar : false ,
                avatarClassName : 'Avatar__big_image',
            });
       
            console.log(true)    
            console.log(state)
        } 
         
        // setTimeout(function(){
        //     console.log( initialState);
        // }, 3000);
        
    }    

    let showLoading  = false;
    if(state.isLoading===true){
        showLoading = <CustoLoader/>
    }else{
        showLoading = '';
    }

    return (
        
        <Container xs={6}  justifycontent="center" >
           
            <Grid container 
                        item 
                        xs={12} 
                        sm={12}
                        lg={12} 
                        spacing={3}  
                        justifycontent="center"
                        alignItems="center"
                        style={{"margin":"auto"}}
            > 
            
                <Card style={{"marginTop":"10px"}}>
                <CardActionArea>
                    <CardMedia 
                   
                    image= {rootSettings.baseURL+theUser.cover_img}
                    title="My profile Pic"
                    style={{height :"50vh"}}
                    >
                    <input   
                    onChange={uploadProfileCoverImg}
                    name="img" 
                    accept="image/*"
                
                    id="raised-button-file"
                    multiple
                    type="file"
                    className="Input__file_hidden"
                    style={{"height":"50vh"}}
                    />
                    </CardMedia>
                    <CardContent>
                    <Typography className="Typegraphy__color_blue" gutterBottom variant="h5" component="h2" style={{"textTrasnform":"capitalize"}}>
                        <Avatar onClick={modalOpenImageWithLargeSize} style={{"width":"100px","height":"100px", "marginBottom":"20px"}} alt={theUser.name} src={rootSettings.baseURL+theUser.img}  />
                        <div className={state.avatarClassName}  style={{"background" : state.avatarBgImage }}></div>  
                        <br></br>
                        {theUser.name} - {(theUser.permission === 1) ? "New Memeber" : "Administrator"} 
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                       {theUser.short_description}
                    </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Link to="/Profile_edit"> 
                        <Button size="small" color="primary">
                            Edit My Profile
                        </Button> 
                    </Link>
                </CardActions>
                </Card>

            </Grid>
            {
                showLoading
            }
        </Container> 
      );
}

export default Profile;