import React, {useContext, useState} from 'react'
import {MyContext} from '../contexts/MyContext'
import * as Mui from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Alert  from '@material-ui/lab/Alert';
import './Profile_edit.css';
import BaseFuncs from  '../libs/base_funcs.js';

//https://www.npmjs.com/package/react-calendar
//checkout url for docs
// imports for card bottom

import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';

import Paper from '@material-ui/core/Paper';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
import CustoLoader from '../components/CustomLoader';

function Profile_edit(){

    const datyDsbl = ['2021-08-26', '2021-08-28']

    const {
            rootState, 
            rootSettings, 
            updateUserDate, 
            updateUserProfilePicture
    } = useContext(MyContext);

    const {theUser} = rootState;

    const initialState = {
        userInfo:{
            name:theUser.name,
            email: theUser.email,
            birthdate: theUser.birthdate,
            city:theUser.city,
            country:theUser.country,
            adress:theUser.adress,
            img:theUser.img,
            Permission:theUser.permission,
            short_description: theUser.short_description,
        },
        errorMsg:'',
        successMsg:'',
        formHasBeenPosted: false,
        isLoading: false
    }
    const [state,setState] = useState(initialState);

    //var someUser = new BaseFuncs();    
    console.log("someUser.datetimeGet()")

     // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();

        setState({
            ...state,
            isLoading: true
        });

        const formAction = 'upd_info';
        const data = await updateUserDate(state.userInfo, formAction);
        //alert(rootSettings.baseImagesUrl);
        
        if(data.success){
            setState({
                ...state,
                successMsg:data.message,
                errorMsg:"",
                isLoading: false
            });
       }else{
            setState({
                ...state,
                successMsg:"",
                errorMsg:data.message,
                isLoading: false
            });
       }

        console.log(state);
    }    

    // On change the Input Value (name, email, password)s
    const onChangeValue = (e) => {
        
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
        
    }

  
    // On change the Input Value (name, email, password)s
    const markDate = (d) => {
        
        //console.log(d)
        //const date = ['2021-08-18'];
        // const view = '';

        function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [year, month, day].join('-');
        }
         
        if( datyDsbl.indexOf(formatDate(d)) > -1 )
        {
            console.log('dates')
            return true;
        }
        
        
        //if( formatDate(d) === '2021-08-10')
        //{return true;}
        //console.log(formatDate(d));

     
    }

    // On file select (from the pop up) 
    const  onFileChange =  async (e) => { 
        
        // Update the state 
        setState({
            ...state,
            isLoading : true 
        });

        const formAction = 'upd_profile_pic';
        const reader = new FileReader();
        
        if(e.target.files.length > 0){
          
            if(window.FileReader) {

                reader.readAsDataURL(e.target.files[0]);
                
                reader.onload = async function(){
                    
                    var dataURL = reader.result;

                    var data = await updateUserProfilePicture( [state.userInfo.email,  dataURL, state.userInfo.img] , formAction);

                    console.log(data)
                    setState({
                        ...state,
                        isLoading: false,
                        userInfo:{
                            ...state.userInfo,
                            img: data.status[1]
                        }

                    });

                    console.log(initialState);
                  

                };

            }
        
        }
        
    }


    // Show Message on Error or Success
    let successMsg = '';
    let errorMsg = '';
    let perm = '';
    let showLoading  = false;
    
    if(initialState.userInfo.Permission === '1'){
        perm = 'Administrator';
    }else if (state.userInfo.Permission === '2'){
        perm = 'New Member';
    }

    if(state.errorMsg){
        errorMsg = <Alert fullWidth variant="outlined" severity="error" style={{width: '100%'}}>{state.errorMsg}</Alert> ;
    }
    if(state.successMsg){
        errorMsg = <Alert fullWidth variant="outlined" severity="success" style={{width: '100%'}}>{state.successMsg}</Alert> ;
    }
    
    if(state.isLoading===true){
        showLoading = <CustoLoader/>
    }else{
        showLoading = '';
    }

   // console.log(isAuth)
   // console.log(theUser)
    return(
        
        <Mui.Container maxWidth="lg" style={{padding: 10}}>
            
            
            <form onSubmit={submitForm} noValidate  style={{width: "100%"}}  encType="multipart/form-data">    
            <Paper item>
            <Mui.Grid container item
                                 xs={12} 
                                 sm={12}
                                 lg={12} 
                                justifycontent="center"
                                alignItems="center"
                                style={{"margin":"auto", "position": "relative"}}
                                spacing={3}
                                > 

                            <Mui.Grid container item
                                 xs={12} 
                                 sm={12}
                                 lg={12} 
                               
                                justifycontent="center"
                                alignItems="center"
                                style={{"margin":"auto", "position": "relative"}}
                            > 

                                <h1 className="H1__profile_header">{theUser.name}</h1>
                                
                                <Mui.FormControl margin="dense" fullWidth >
                                    <Avatar className="Avatar__file_hidden" variant="square" alt={theUser.name} src={rootSettings.baseURL+state.userInfo.img}  />
                                    <input   
                                        
                                        onChange={onFileChange}
                                        name="img" 
                                        accept="image/*"
                                        
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                        className="Input__file_hidden"
                                        
                                        />
                                </Mui.FormControl>

                            </Mui.Grid>    
                    
                            <Mui.Grid container item
                                        xs={12} 
                                        sm={12}
                                        lg={6} 
                                    
                                        justifycontent="center"
                                        alignItems="center"
                                        style={{"margin":"auto", "position": "relative"}}
                                        > 
                                        
                                        <Mui.FormControl margin="dense" fullWidth >
                                        
                                        
                                            <Mui.TextField variant="outlined" label="Full Name"  style={{width: "100%"}}   name="name" required type="text" value={state.userInfo.name} onChange={onChangeValue} placeholder="Enter your Name"/>
                                            

                                        </Mui.FormControl>   

                                        <Mui.FormControl margin="dense" fullWidth >
                                        
                                            
                                            <Mui.TextField variant="outlined" label="Access level"  disabled style={{width: "100%"}}   name="permission" required type="text" value={perm} onChange={onChangeValue} placeholder="Enter your Name"/>
                                            

                                        </Mui.FormControl>    

                                        <Mui.FormControl margin="dense" fullWidth>
                                    
                                        
                                            <Mui.TextField 
                                                style={{width: "100%"}} 
                                                id="date"
                                                type="date"
                                                value={state.userInfo.birthdate}
                                                name="birthdate"
                                                onChange={onChangeValue} 
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                variant="outlined" label="Birthdate"
                                            />
                                
                                        </Mui.FormControl> 

                            </Mui.Grid>
                            <Mui.Grid container item
                                        xs={12} 
                                        sm={12}
                                        lg={6} 
                                        justifycontent="center"
                                        alignItems="center"
                                        style={{"margin":"auto", "position": "relative"}}
                                        >         
            

                                        <Mui.FormControl margin="dense" fullWidth >
                                        
                                                <Mui.TextField    
                                                    name="country" 
                                                    required 
                                                    type="text" 
                                                    value={state.userInfo.country} 
                                                    onChange={onChangeValue} 
                                                    placeholder="Enter your country"
                                                    variant="outlined" label="Enter your country"
                                                    style={{width: "100%"}} 
                                                />
                                        
                                        </Mui.FormControl>        

                                        <Mui.FormControl margin="dense" fullWidth >
                                        
                                                <Mui.TextField    
                                                    name="city" 
                                                    required 
                                                    type="text" 
                                                    value={state.userInfo.city} 
                                                    onChange={onChangeValue} 
                                                    placeholder="Enter your city"
                                                    style={{width: "100%"}} 
                                                    variant="outlined" label="Enter your city"
                                                />
                                        
                                        </Mui.FormControl>        

                                        <Mui.FormControl margin="dense" fullWidth >
                                            
                                                <Mui.TextField    
                                                    name="adress" 
                                                    required 
                                                    type="text" 
                                                    value={state.userInfo.adress} 
                                                    onChange={onChangeValue} 
                                                    placeholder="Enter your adress"
                                                    style={{width: "100%"}} 
                                                    variant="outlined" label="Enter your adress"
                                                />
                                        
                                        </Mui.FormControl>        



                            </Mui.Grid>     
                            <Mui.Grid container item
                                        xs={12} 
                                        sm={12}
                                        lg={12} 
                                        justifycontent="center"
                                        alignItems="center"
                                        style={{"margin":"auto", "position": "relative"}}
                                        >                
                                            <Mui.FormControl margin="dense" fullWidth >
                                                        
                                                    <Mui.TextField    
                                                        name="short_description" 
                                                        required 
                                                        type="text" 
                                                        value={state.userInfo.short_description} 
                                                        onChange={onChangeValue} 
                                                        placeholder="Enter your short description"
                                                        style={{width: "100%"}} 
                                                        variant="outlined" label="Your short description"
                                                        minRows={5}
                                                        multiline
                                                    />
                                            
                                            </Mui.FormControl>  

                                            {errorMsg}
                                            {successMsg}
                                            <Mui.FormControl margin="dense" fullWidth >
                                                <Mui.Button  variant="contained" color="primary" type="submit">Update the data</Mui.Button >
                                            </Mui.FormControl>          
                            </Mui.Grid>                 
                </Mui.Grid>         
                    {
                        showLoading
                    }
            
            </Paper>          
            </form>  
                  
        </Mui.Container>
        
    )
}

export default Profile_edit;