import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

function CarList(){

    const cars = [
        {alias: "fiat500", type:"Fiat", model:"500", color:"white", image: "https://www.autoitalia.ro/sites/all/modules/autoitalia/offers/modules/fiat_500_oferta_speciala/images/slide-2.jpg"},
        {alias: "teslams", type:"Tesla", model:"model s", color:"green", image: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Tesla_Model_S_Japan_trimmed.jpg"},
        {alias: "opelmokkax", type:"Opel", model:"Mokka X", color:"white", image: "https://static.automarket.ro/v5/img/auto_resized/db/model/003/777/847411l-1000x637-b-885c0d12.jpg"},
    ];

    return (
            <Grid style={{padding : "20px"}} container spacing={3}>
                  {cars.map(car => (
                  <Grid item xs={12} sm={6} lg={3} key={car.alias} >
                    <Link to={`/cars/${car.alias}`}>
                      <Card>
                        <CardActionArea>
                          <CardMedia
                            style={{height : "300px"}}
                            image={car.image}  
                            title={car.type + " - " +car.model}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                            {car.alias}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                            {car.color}  
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button size="small" color="primary">
                            Share
                          </Button>
                          <Button size="small" color="primary">
                            Learn More
                          </Button>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                  ))}
            </Grid>
    );

  
}
 
export default CarList;