import React  from 'react'
import * as Mui from '@material-ui/core';


function HomePage(){

  return (
    <Mui.Container xs={12}  justifycontent="center" >

                <Mui.Grid container 
                              item 
                              xs={12} 
                              sm={12}
                              lg={12} 
                              spacing={3}  
                              justifycontent="center"
                              alignItems="center"
                              style={{"margin":"auto"}}
                              > 
                
                            <Mui.Box borderColor={"blue"} style={{"width":"100%", marginTop : "20px"}}>
                                <Mui.Card  variant="outlined">
                                    <Mui.CardContent>

                                        <h1 style={{"textAlign":"center", "fontFamily":"arial"}}>Home Page</h1>
                                     

                                    </Mui.CardContent>
                                </Mui.Card>
                            </Mui.Box>
                </Mui.Grid>

    </Mui.Container>                             
  );
}
 
export default HomePage;