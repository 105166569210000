import React, { createContext,Component } from "react";
import axios from 'axios'

class BaseFuncs extends Component{

    constructor(){
        super();
    }

    datetimeGet() { 
        console.log('libs/base_funcs.js') 
        return "libs/base_funcs.js"
    }

}

export default BaseFuncs;