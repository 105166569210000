import React, {useContext, useState} from 'react'
import {MyContext} from '../contexts/MyContext'
import {
    useHistory,
    Link
  } from "react-router-dom";
import * as Mui from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Fade from '@material-ui/core/Fade';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InfoIcon from '@material-ui/icons/Info';
import LockOpenIcon from '@material-ui/icons/LockOpen';

function Navmenu(){

    const {rootState,rootSettings,logoutUser} = useContext(MyContext);
    const {isAuth,hasAdminRole,theUser} = rootState;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const history = useHistory();

    const handleClose = async (e) => {
    
        if(e.currentTarget.title ==='logout'){
            await logoutUser()
            history.push('/login')
        }
        setAnchorEl(null);
    };

    let AdminOnly = '';

    if (hasAdminRole) {
        AdminOnly = <Link to="/dashboard">
                        <Mui.MenuItem onClick={handleClose}>
                            <DashboardIcon className="Icon__padding_right10 Icon__colorblue"/>
                            Dashboard
                            </Mui.MenuItem>
                    </Link> 
    }   

    /// is user logged in 
    if (isAuth) {

        return (

            <Mui.AppBar position="static">
                <Mui.Toolbar>
                   
                    <Mui.Typography variant="h6" style={{"flexGrow":"1"}}>
                        {theUser.name.toUpperCase()}
                    </Mui.Typography>

                    <Mui.IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleClick}
                        color="inherit"
                    >
                        <Avatar style={{"width":"40px","height":"40px"}} alt={theUser.name} src={rootSettings.baseURL+theUser.img}  />
                       
                    </Mui.IconButton>

                    <Mui.Menu
                        
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        style={{transform: 'translateY(30px)'}}
                    >
                        {AdminOnly}
                        
                        <Link to="/cars_list">
                            <Mui.MenuItem onClick={handleClose}>
                                <AccountCircleIcon className="Icon__padding_right10 Icon__colorblue"/> Cars List
                            </Mui.MenuItem>
                        </Link> 
                        <Link to="/profile_edit">
                            <Mui.MenuItem onClick={handleClose}>
                                <AccountCircleIcon className="Icon__padding_right10 Icon__colorblue"/>
                                Edit my profile
                            </Mui.MenuItem>
                        </Link>
                        <Link to="/profile">
                            <Mui.MenuItem onClick={handleClose}>
                                <InfoIcon className="Icon__padding_right10 Icon__colorblue"/>
                                Profile Info</Mui.MenuItem>
                            </Link> 
                        
                        {/* LOGOUT FROM THE ACCOUNT */}
                        <Link to="/">
                            <Mui.MenuItem title="logout" onClick={handleClose}>
                                <LockOpenIcon className="Icon__padding_right10 Icon__colorblue"/>    
                                Logout
                            </Mui.MenuItem>
                        </Link> 
                    

                    </Mui.Menu>

                </Mui.Toolbar>
            </Mui.AppBar>


        );

    // not loggedin
    }else{
        
        return (

            <Mui.AppBar position="static">
                <Mui.Toolbar>

                    <Mui.Typography variant="h6" style={{"flexGrow":"1"}}>
                        Hello there
                    </Mui.Typography>

                    <Mui.IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleClick}
                        color="inherit"
                    >
                        <Avatar style={{"width":"40px","height":"40px"}} alt="user"  />
                       
                    </Mui.IconButton>

                    <Mui.Menu
                       
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        style={{transform: 'translateY(30px)'}}
                    >
                    
                        <Link to="/login" ><Mui.MenuItem onClick={handleClose}>Login</Mui.MenuItem></Link>
                        <Link to="/register" ><Mui.MenuItem onClick={handleClose}>Register</Mui.MenuItem></Link>
                   
                    </Mui.Menu>

                </Mui.Toolbar>
            </Mui.AppBar>

        );

    }    

   
}

export default Navmenu;