import React from 'react';
// Importing the Context Provider & Home Component
import MyContextProvider from './contexts/MyContext';
import Home from './pages/Home'
import NavMenu from './components/Navmenu'


function App() {

  return (
    <MyContextProvider>
       <NavMenu/>
       <Home/>
    </MyContextProvider>
  );
}

export default App;