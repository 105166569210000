import React, {useContext, useState, useEffect} from 'react'
import {MyContext} from '../contexts/MyContext'
import {
    Switch,
    Route,
  } from "react-router-dom";
  import { Redirect } from 'react-router';

// Importing the Login & Register Componet
import Login from './Login'
import Register from './Register'
import Profile from './Profile'
import Profile_edit from './Profile_edit'
import Dashboard_page from './Dashboard_page'
import CarsList from './CarsList'
import CarDetails from './CarDetails'
import NotFound from './NotFound';
import HomePage from './HomePage';
import Activate from './Activate';
import PermissionDenied from './PermissionDenied';

import CustomLoader from '../components/CustomLoader'

function Home(){

    const [isLoading, setLoading] = useState(true);   

    const {rootState,} = useContext(MyContext);
    const {isAuth,hasAdminRole} = rootState;
    
    function initalLoad() {
        return new Promise(resolve => setTimeout(
            () => resolve(), 500)
        );
    }

    useEffect(() => {
        initalLoad().then(() => {
           setLoading(!isLoading);
        });
    }, []);


    // keep the loading until app is ready  
    if (isLoading) {
    return <CustomLoader/>;
    }

    // If user Logged in
    if(!isAuth)
    {

        //console.log("Login")
        //return <Login/>;
        return (
            <Switch> 
               
                <Route path="/" component={HomePage} exact/>
                <Route path="/login" component={Login} exact> 
                </Route>
                
                <Route path="/register" component={Register} exact>
                </Route>
                
                <Route path="/activate/:tk" component={Activate}>
                </Route>
          
                <Route path='*' exact={true} component={NotFound}>
                </Route>

            </Switch>
        );

    }

    // Showing Login Or Register Page According to the condition
    else{
        

        if(hasAdminRole){

            return (
                <Switch>
                    {/*AVAILABLE ROUTING*/}  
                    <Route path="/" component={HomePage} exact/>
                    <Route path="/profile" component={Profile} exact/>
                    <Route path="/profile_edit" component={Profile_edit} exact/>
                    <Route path="/dashboard" component={Dashboard_page} exact />
                    <Route path="/cars_list" exact>
                        <CarsList/>
                    </Route>    
                    <Route path="/cars/:id" >
                        <CarDetails/>
                    </Route>

                    {/*REDIRECTIONS*/}
                    <Redirect from="/register" to="/cars_list" />
                    <Redirect from="/login" to="/profile" />
                    <Redirect from="/activate/:tk" to="/nopermission" />
                    
                    {/*NO PERMISSION*/}
                    <Route path="/nopermission" >
                        <PermissionDenied/>
                    </Route>

                    {/*NO ROUTE FOUND*/}
                    <Route path='*' exact={true} component={NotFound}>
                    </Route>
                   
                </Switch>
                
            );

        }else if (!hasAdminRole){

            return (
                
                <Switch>       
              
                    {/*AVAILABLE ROUTING*/}   
                    <Route path="/" component={HomePage} exact/>
                    <Route path="/profile" component={Profile} exact/>
                    <Route path="/profile_edit" component={Profile_edit} exact/>

                    <Route path="/cars_list" exact>
                        <CarsList/>
                    </Route>    
                    <Route path="/cars/:id" >
                        <CarDetails/>
                    
                    </Route>

                    {/*REDIRECTIONS*/}
                    <Redirect from="/register" to="/profile" />
                    <Redirect from="/login" to="/profile" />
                    <Redirect from="/activate/:tk" to="/nopermission" />
                    
                    {/*NO PERMISSION*/}
                    <Route path="/nopermission" >
                        <PermissionDenied/>
                    </Route>

                    {/*NO ROUTE FOUND*/}
                    <Route path='*' exact={true} component={NotFound}>
                    </Route>
                    
                </Switch>
                
            );

        }


    }
   
}

export default Home;