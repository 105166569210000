import './CustomLoader.css';

function CustoLoader(){

    return(
        <div className="loading">
            <div className="loadingSpinner">
            </div>
        </div>
    ) 

}

export default CustoLoader;