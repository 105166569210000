import React, { useEffect,useContext,useState } from 'react';
import { useParams, Link } from "react-router-dom";
import {MyContext} from '../contexts/MyContext'

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './Activate.css';
import Alert  from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CustoLoader from '../components/CustomLoader';

function Activate (){

  const { tk } = useParams();
    
  const {
        activateUser,
    } = useContext(MyContext);  
    
    const initialState = {
        requestData: {},
        isLoading: false,
        getData: false
    }
    const [state,setState] = useState(initialState);
    const parameters = {};
    parameters.tk = tk;

    useEffect(()=>{
            
            const getData = async (event) => {
            
            
                setState({
                    ...state,
                    isLoading: true
                });
        
               
                const formAction = 'activate_user';
                const axios = await activateUser(
                    parameters,
                    formAction
                );
                
                setState({
                    ...state,
                    requestData:axios,
                    isLoading: false,
                    getData: true
                });
        
                console.log(axios)
        
            }
            getData();
      
    }, [ ]);
    
    let renderd = ''
    let showLoading  = false;

    console.log(state)    

    if(state.requestData.success === 1){
        renderd =     <Grid container item >
                        <Grid container >
                            <Grid item xs={12} className="Item__add_padding">
                                <Paper className="Item__add_padding">

                                    
                                    <Alert variant="outlined" severity="success" >Your account has been successfully activated!
                                        <br></br>
                                        <br></br>
                                        <Link to="/login" >
                                            <Button variant="contained" color="primary" >Click here to login</Button>    
                                        </Link>
                                    </Alert>

                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>;
                    
    }else if(state.requestData.success === 0){
        renderd =     <Grid container item >
                        <Grid container >
                            <Grid item xs={12} className="Item__add_padding">
                                <Paper className="Item__add_padding">

                                    
                                    <Alert variant="outlined" severity="error" >Your account could not be activated!</Alert>

                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>;
    }

    if(state.isLoading===true){
        showLoading = <CustoLoader/>
    }else{
        showLoading = '';
    }
        
    return (
    
            <Container xs={12}  justifycontent="center" >

                <Grid container item >
                   
                        <Grid container >
                        
                            <Grid item xs={12} className="Item__add_padding">
                                <Paper className="Item__add_padding">

                                    Your activation token is : <b>{tk}</b>

                                </Paper>
                            </Grid>
                        
                        </Grid>
                            
                </Grid>    

                {showLoading}
                {renderd}
            
            </Container>               

  );
}
 
export default Activate;