import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';

const NotFound = () => {

    return (
        <Container maxWidth="lg">
            <Grid style={{padding : "20px"}} container spacing={2}>
            
                <Grid item xs={12} sm={12} lg={12}>
                    
                    <Card>
                        
                        <CardActionArea>
                           
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                You do not have permission to view this page!
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                Warrning
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    
                    </Card>
        
                </Grid>
            
            </Grid>
        </Container>
    )

}
 
export default NotFound;