import React, {useContext,useState} from 'react'
import {MyContext} from '../contexts/MyContext'
import * as Mui from '@material-ui/core';
import Alert  from '@material-ui/lab/Alert';

import { 
    Link,
} from "react-router-dom";

function Register(){
    const {registerUser} = useContext(MyContext);
    
    const initialState = {
        userInfo:{
            name:'',
            email:'',
            password:'',
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }
    
    // Show Message on Error or Success
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <Alert variant="outlined" severity="error" >{state.errorMsg}</Alert> ;
    }
    if(state.successMsg){
        errorMsg = <Alert variant="outlined" severity="success" >{state.successMsg}</Alert> ;
    }

    return(

        <Mui.Container xs={6} justifycontent="center" >

                <Mui.Grid container 
                              item 
                              xs={12} 
                              sm={12}
                              lg={6} 
                             spacing={3}  
                             justifycontent="center"
                             alignItems="center"
                             style={{"margin":"auto"}}
                > 
                    <Mui.Box p={2} borderColor={"blue"} style={{"width":"100%"}}>
                        <Mui.Card  variant="outlined">
                            <Mui.CardContent>   
                                <h1 style={{"textAlign":"center", "fontFamily":"arial"}}>Register</h1>

                                <form onSubmit={submitForm} noValidate>
                                
                                <Mui.FormControl margin="dense" fullwidth="true" style={{width: "100%"}} >
                                   
                                    <Mui.TextField 
                                        name="name" 
                                        autoComplete="username"  
                                        required type="text" 
                                        value={state.userInfo.name} 
                                        onChange={onChangeValue} 
                                     

                                        placeholder="Full Name"
                                        label="Full Name"
                                        variant="outlined"
                                    />
                                </Mui.FormControl>

                                <Mui.FormControl margin="dense" fullwidth="true" style={{width: "100%"}} >
                                   
                                    <Mui.TextField 
                                        name="email" 
                                        autoComplete="email-adress"  
                                        required type="email" 
                                        value={state.userInfo.email} 
                                        onChange={onChangeValue} 
                                   
                                        
                                        label="Email"
                                        placeholder="Email"
                                        variant="outlined"
                                    />
                                </Mui.FormControl>

                                <Mui.FormControl margin="dense" fullwidth="true" style={{width: "100%"}} >
                                  
                                    <Mui.TextField 
                                        name="password" 
                                        autoComplete="current-password"  
                                      
                                        required type="password" 
                                        value={state.userInfo.password} 
                                        onChange={onChangeValue} 
                                  

                                        label="Email"
                                        placeholder="Email"
                                        variant="outlined"
                                    />
                                </Mui.FormControl>
                            
                                {errorMsg}
                                {successMsg}

                                <Mui.FormControl margin="dense" fullwidth="true" style={{width: "100%"}} >
                                    <Mui.Button type="submit" variant="contained" color="primary"  >Sign Up</Mui.Button>
                                </Mui.FormControl>

                                </form>

                                <Mui.FormControl margin="dense" fullwidth="true" style={{width: "100%"}} >
                                    <Link to="/login" >
                                        <Mui.Button variant="contained" color="default" >Have an account? Login</Mui.Button>
                                    </Link>
                                </Mui.FormControl>
                            </Mui.CardContent>
                        </Mui.Card>    
                    </Mui.Box>

                </Mui.Grid>

        </Mui.Container>      
    );
}

export default Register